.crmSidebar {
    // background-color: #3a0305;
    .logo {
        // background-color: #000;
        width: 100%;
        height: 64px;
        padding: 10px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        svg{
            max-width: 100%;
            height: auto;
    
        }
    }
.sidebarItems{
    
    svg{
        width: 18px;
        height: 18px;
        path{
            fill:#000;
        }
        
    }
}
}
