.avatat{
    display: flex;
    align-items: center;
    position: relative;
    &.isAction{
        padding-right: 75px;    
    }
    h5{
        font-size: 13px;
        font-weight: 500;
        margin: 0;
        // line-height: 1;
    }  
    h6{
        font-size: 10px;
        margin: 0; 
    }
    &.avatarDark{
        h5,h6{
            color: #fff;  
        } 
    }
    .avt_content{
        padding-left: 10px;
    }
    .avtaction{
        position: absolute;
        right: 0;
        top: 5px;
        a{
            &:not(:last-child){
                margin-right: 10px !important;
            }
        }
    }
    .avatar-status-title{
        width:calc(100% - 40px);
        h5{
            white-space: nowrap;
            overflow: hidden;
           text-overflow: ellipsis;
        }
    }
}