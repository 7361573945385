// .lead-dtl-page

.lead-dtl-page {
 display: flex;
 flex-direction: column;
 padding: 15px;
 height: 100%;
 .lead-dtl-page-body{
  flex: 1;
}
 .lad-header {
  padding: 20px 20px;
  border-radius: 4px;
  border: 1px solid rgb(207, 207, 207);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // padding-left: 40px;
  position: relative;
  a.lead-close {
   position: absolute;
   right: 10px;
   top: 10px;
   width: 20px;
   height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   border: 1px solid $primary;
  }
  // .lead-close{
  //     position: absolute;
  //     z-index: 10;
  //         left: 0;
  //         top: 0;
  //         bottom: 0;
  //         height: 100%;
  //         width: 25px;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //     font-size: 30px;
  //     background-color: rgb(223, 223, 223);
  //     transition: all .5s ease;
  //     &:hover{
  //         background-color: #ccc;
  //         // color: #fff;
  //     }

  // }
  .avatar-lead {
   h5 {
    font-size: 22px !important;
    text-transform: capitalize;
    margin-bottom: 6px !important;
    overflow: initial !important;
   }
  }
  .lead-uiblk {
   margin: 0;
   padding: 0;
   margin-top: 4px;
   list-style: none;
   display: flex;
   // flex-direction: column;
   // row-gap: 10px;
   li {
    font-size: 14px;
    &:not(:last-of-type) {
     margin-right: 10px;
    }
    .hio-tag {
     padding: 3px 7px;
     line-height: 1;
     font-size: 12px;
     height: auto;
    }
   }
  }
 }
 .ant-tabs {
  flex: 1;
  .ant-tabs-nav {
   background-color: #fff;
   z-index: 10;
  }
  .ant-tabs-content-holder {
   padding: 15px;
   border-radius: 7px;
   background-color: #fff;
   height: 100%;
   .ant-tabs-content {
    height: 100%;
   }
  }
 }
}

.activity-layout {
 display: flex;
 flex: 1;
 position: relative;
 height: 100%;
 .inner-act-layout {
  display: flex;
  width: 100%;
  // position: absolute;
  // height: 100%;
  // top: 0;
  // right: 0;
  // left: 0;
 }
 .activity-content {
  width: calc(100% - 190px);
  .ant-card-body{
    position: relative;
    // max-height: 500px;
    overflow: auto;
  }

 }
 .activity-sidemenu {
  width: 320px;
  position: sticky;
  top: 10px;
  max-height: 450px;
  overflow: auto;
  a {
   display: block;
   padding: 5px;
   border-radius: 3px;
   border: 1px solid rgb(216, 216, 216);
   position: relative;
   overflow: hidden;
   &.active {
    &::after {
     font-family: 'Font Awesome 5 Pro';
     font-weight: 300;
     content: '\f105';
     color: #fff;
     position: absolute;
     display: flex;
     align-items: center;
     justify-content: center;
     top: 0;
     right: 0;
     bottom: 0;
     background-color: $primary;
     z-index: 2;
     width: 10px;
     // border-radius: 10px;
    }
   }
   &:not(:last-of-type) {
    margin-bottom: 7px;
   }
   &.blk-link {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.15s;
    outline: 1px solid #fff;
    &.active {
     border-color: $primary !important;
     outline: 1px solid $primary;
    }
    &:nth-child(even) {
     background-color: #eff6fc;
    }
   }
  }
  .ant-badge {
   width: 60px;
   display: inline-block;
   margin-bottom: auto;
   position: relative;
   margin-top: 5px;
   .ant-avatar {
    background-color: #dde8f2;
    svg {
     width: 30px;
     height: 30px;
    }
   }
   i:not(.fa-globe,.font-icon) {
    transform: translate(-35%, 0) !important;
    font-size: 15px;
    &.fa-heart {
     color: red;
    }
    &.fa-filter {
     color: $primary;
    }
    &.fa-eye {
     color: #27a645;
    }
   }
  }
  .act-content {
   display: inline-block;
   width: calc(100% - 60px);
   padding-left: 10px;
   .act-label {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
   }
   .act-head {
    color: #626365;
    font-size: 13px;
    font-weight: 600;
    .text-primary {
     color: $primary !important;
    }
   }
   h4 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 3px;
   }
   ul.prop-sec {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
     display: inline-block;
     svg {
      max-width: 15px;
      max-height: 15px;
     }
     &:not(:last-child) {
      margin-right: 8px;
     }
    }
   }
  }
 }
}

.activity-card {
 color: rgba(0, 0, 0, 0.85);
 font-size: 14px;
 position: relative;
 background: #fff;
 border-radius: 6px;
 // padding: 15px;
 box-shadow: 1px 1px 6px -4px #0005;
 .arrow-blk-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
 }
 &:not(:last-of-type) {
  margin-bottom: 7px;
 }
 .time-stamp {
  font-size: 11px;
  color: #888;
  padding: 4px 15px;
 }
 .activity-card-body {
  padding: 8px 15px;
 }
}

.act--card-wrapper {
 position: relative;
 height: 100%;
 display: flex;
 .act-link {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
 }
 .similar-prop-btn {
  position: relative;
  z-index: 2;
 }
}

.act--card {
 width: 100%;
 display: flex;
 padding: 10px;
 box-shadow: 1px 1px 9px -4px #0006;
 height: 100%;
 border-radius: 7px;
 overflow: hidden;
 h5,
 h4 {
  margin: 0;
 }
 .act-content {
  width: calc(100% - 60px);
  padding-left: 9px;
  .act-label {
   font-size: 12px;
   font-weight: 500;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
  }
  h4 {
   white-space: nowrap;
   text-overflow: ellipsis;
   width: 100%;
   overflow: hidden;
  }
 }
 .prop-sec {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 5px;
  align-items: center;
 }
}

// lead-tile-blk

.lead-list-body {
 padding: 0px 15px ;
 display: flex;
 flex-direction: column;
 .lead-tile-blk {
  // margin-bottom: 10px;
  height: 50px;
  display: flex;
  align-items: center;

  .ant-menu {
   .ant-menu-item {
    border: 1px solid $primary;
    border-radius: 5px;
    color: $primary;
    padding: 0px 12px;
    line-height: 32px;
    &:not(:last-of-type) {
     margin-right: 6px;
    }
    &::after {
     content: none !important;
    }
    &.ant-menu-item-selected {
     background-color: $primary;
     color: $primary;
     color: #fff;
    }
   }
  }
  .relator-menu{
      .realtor-menu-item{
        font-size: 16px;
        cursor: inherit;
          color: white;
          border-color: $indigo;
          @include simpleBg($indigo)
      }
  }
 }
 .infinity-table {
  height: calc(100% - 50px) !important;
 }
}

.background-textarea {
 padding-right: 100px;
}

.background-form {
 position: relative;

 .background-button {
  position: absolute;
  right: 15px;
  top: 12px;
 }
}

.mail-preview-activity {
  .bold {
   font-weight: 600;
   font-size: 16px;
  }
  .body {
   font-size: 16px;
  }
  .message {
   a {
    color: $primary;
   }
  }
 }
 .attachments-wrapper{
  margin-top: 1rem;
  .attachment{
    border-radius: 6px; 
    background-color:  #eff6fc;
    margin: 0 10px;

    i{
      font-size: 32px;
      cursor: pointer;
      padding: 0.7rem;
      color: $primary;
    }
  }
}

.basic-details{
  .url{
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
    &.link{
      color: $primary;
      cursor: pointer;
    }
  }
  
}

.pro--card {
.list-modal
{
    display: flex;
    align-items: center;
   ul
   {
    list-style-type: none;
    .list-modal-title
    {
        font-size: 16px;
        font-weight: 600;
    }
    .list-modal-content
    {
        font-weight: 600;
         font-size: 14px;
    }
    .list-modal-agent
    {
        border: 1px solid $primary;
        padding: 2px 5px;
        border-radius: 6px;
        color: $primary;
    }
   }
}
.list-modal-date
{
     text-align: center;
     background-color: $primary;
     color: #ffff;
     padding: 5px 0px;
     border-radius: 5px;
}
.close-icon{
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1;
  i{
    font-size: 17px;

  }
}
}

.table-select-all-arrow{
  cursor: pointer;
  color: gray;
  font-weight: 500;
  position: absolute;
  right: 0px;
  // top: 50%;
  // transform: translateY(-50%);
  top: 14px;
  font-size: 12px;

}