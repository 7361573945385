.b-day-wish-form {
 .menu {
  .ant-radio-button-wrapper {
   min-width: 80px;
   margin-bottom: 14px;
   text-align: center;
   &.ant-radio-button-wrapper-checked {
    background-color: $primary;
    border-color: $primary;
    color: white;
   }
  }
 }
}

.signature-section {
 .tox-tinymce {
  &[role='application'] {
   height: calc(100vh - 300px) !important;
   min-height: 280px !important;
  }
 }
}

.tiny-loading-sec{
    height: calc(100vh - 300px) !important;
    min-height: 280px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
