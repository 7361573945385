.listing-page{
    .listing--nav{
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        column-gap: 10px;
        // margin-bottom: 15px;
        align-items: center;
        padding: 6px 15px;
        z-index: 10;
        transition: all .5s ease;
        height: 50px;
        background-color:$primary;
        // &.has-sticky{
        //     background-color: #fff;
        //     padding: 15px;
        // }
        li{
            a{
                border: 1px solid #fff9;
                display: block;
                padding: 8px 23px;
                box-shadow: 1px 1px 10px -4px #0003;
                border-radius: 50px;
                transition: all .5s ease;
                line-height: 1;
                color: #fff ;
                &.active,
                &:hover{
                    background-color: darken($primary, 10);
                    border-color: darken($primary, 10) ;
                    color: #fff;
                }
            }
        }
    }
    .pro-listing{
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 1px 1px 10px -4px #0003;
        // padding: 15px;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;

    }

    .property-lis{
       
        height: calc(100% - 60px);
        // overflow: auto;
        .ant-checkbox-group{
            display: flex;
            height: 100%;
            overflow: auto;
            padding: 15px;
            @include smoothScroll()
        }
    }
}