
.timelineblock{
   
    .ant-timeline{
        padding: 20px;
        padding-left: 40px;
        .ant-timeline-item{
            .ant-timeline-item-tail{
                border-left: 2px solid $primary;
            }
            .ant-timeline-item-head-custom{
                transform: translate(-50%,0%);
                top: 0;
                .ant-card{
                    margin: 0;
                    background-color: $primary;
                    color: #fff;
                    h4{
                        margin-bottom: 3px;
                        font-size: 30px;
                        color: #fff;
                        font-weight: 700;
                    }
                    h6{
                        margin: 0;
                        color: #fff;
                    }
                }
            }
            .ant-timeline-item-content{
                top: 0;
                margin-left: 60px;
            }
            .ant-timeline-item-head{
                i{
                    font-size: 20px;
                }
            }
            .ant-card{
                border-color: rgba($primary,.5);
                border-radius: 6px;
                margin-bottom: 7px;
                // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.089);
               .ant-card-body{
                   padding: 15px;
               }
                h5{
                    margin-bottom: 3px;
                }
                .time-stamp{
                    font-size: 11px;
                    color: #888;
                }
            }
        }
    }
}