.base-selecter-blk{
    .selector-search{
       margin-bottom: 10px;
    }
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            overflow-y: auto;
            max-height: 400px;
            li{
                a{
                    display: block;
                    transition: all .5s ease;
                    padding: 7px 15px;
                    color: #222;
                    &.active
                   {
                        background-color: #eee;
                    }
                    &:hover{
                        background-color: #ddd;
                    }
                }
            }
        }
    }
    .base-selector-sp-blk{
        .selector-search{
            margin-bottom: 10px;
         }
             ul{
                 margin: 0;
                 padding: 0;
                 list-style: none;
                 overflow-y: auto;
                 max-height: 400px;
                 li{
                     a{

                         transition: all .5s ease;
                         padding: 7px 7px;
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         color: #555 !important;
                         &.active
                        {
                             background-color: #eee;
                         }
                         &:hover{
                             background-color: #eee;
                         }
                         .base-selector-sp-title{
                            font-weight: 500;
                            font-size: 16px;
                            margin: 0;
                         }
                     }
                 }
             }                                              
    }

    .modal-has-base-selector {
        .ant-modal-content{
            overflow: hidden;
            padding: 0;
            .ant-modal-header{
                padding: 15px;
                margin: 0;
                background-color: #eee;
            }
            .ant-modal-footer{
                padding: 15px;
                margin: 0;
                background-color: #eee; 
            }
        }
        .selector-search{
            margin: 0 !important;
            padding: 10px 15px !important;
            border: 1px solid #eee;
            // background-color: #f7f7f7                           ;
        }
    }