.auth-layout{
    width: 100%;
    height: 100vh;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-block{
        background-color: #fff;
        padding:  50px;
        border-radius: 10px;
        width: 500px;
        // height: 600px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        .login-header{
            text-align: center;
        
        
            img{
                width: 60px;
                margin-bottom: 6px;
            }
            h3{
                font-size: 30px;
            }
            p{
                font-size: 13px;
                color: #777;
            }
        }
    }
}