.swiper-wrapper{
  .swiper-slide{
    img{
      &.swiper-imgs{
        object-fit: cover;
        height: 370px;
      }
    }
  }
}


.pro-details-modal{
  height: calc(100vh - 20px);
  .ant-modal-content{
      padding: 0;
      background-color: #f3f3f3;
      display: flex;
      flex-direction: column;
      height: 100%;
      .ant-modal-header{
          padding: 15px;
      }
      .ant-modal-body{
          flex: 1;
          position: relative;
          .pro-dtls-page{
              padding: 15px;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              overflow: auto;
              bottom: 0;
              .property-image-block{
                  margin: 0;
              }
          }
      }
  }
}

// .simlar-property-card{
  
// }
.pro-dtls-page{
  .pro-img-block{
    img{
      object-fit: cover;
      height: 370px;
    }
  }
  .property-image-block{
    margin-top: 30px;
    .swiper-wrapper{
      .swiper-slide{
        img{
          &.prop-swiper-image{
            object-fit: cover;
            height: 300px;
          }
        }
      }
    }
  }
    .btnstyle{
      border: 1px solid red;
      color: red;
      padding: 7px;
      border-radius: 20px;
      display: block;
      text-align: center;
      width: max-content;
      max-width: 100%;
      margin: 10px auto 0 auto;
      font-size: 14px;
      font-weight: 500;
      transition: all 0.3s;
      &:hover{
        background-color: red;
        color: white;
      }
    }
    .border-table-blk{
        border-radius: 6px;                        
        overflow: hidden;
        // margin: 10px 0;
        background-color: #ffffff;
    }
    table.theme {
        width: 100%;
        text-align: center;
        border-color: #fff;
        border-spacing: 50px 0;
  
        thead {
          background-color: #E2EAF1;  
          th {
            padding: 10px;
            font-size: 16px;
            font-weight: 500;   
            color: #000000;   
          }
        }   
        tbody {
          background-color: #EFF4F8;  
          td {
            padding: 10px;
            font-size: 16px;
            color: #2a2a2a;
            svg {
              margin: 0 5px 0 0;
              position: relative;
              top: -1px;
            }
          }
        }
    }
    ul.key-facts {
        list-style: none;
        column-count: 2;
        padding: 0;
        margin: 0;
        &.one-column {
          column-count: 1;
          li {
            .two {
              span {
                display: inline-block;
                &:nth-child(2) {
                  text-align: initial;
                }
              }
            }
          }
        }
        li {
          margin-bottom: 10px;  
          .two {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            &.sample {
              span {
                width: 40%;
                &:nth-child(2) {
                  width: calc(60% - 10px);
                }
              }
            }
            span {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              display: inline-block;
              width: calc((100% - 10px) / 2);
              &:nth-child(1) {
                margin-right: 10px;
              }
              &:nth-child(2) {
                text-align: right;
                color: #000000;   
                font-weight: 500;                                     
              }
            }
          }
          .progress-block {
            position: relative;
            width: 100%;
            padding-top: 15px;
            margin: 10px 0;
            img.pointer {
              position: absolute;
              top: 0;
            }
          }
        }
    }
    .description {
        margin: 15px 0;
        h5 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          color: #000000;   
        }   
        p{
          color: #454545;
          line-height: 27px;
        }
    }
    .property-block {
        h4 {
          font-size: 16px;
          font-weight: 600;
          color: #000000;  
          margin: 10px 0 15px 0;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            margin-bottom: 10px;  
            .two {
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #333333; 
                display: inline-block;
                width: calc((100% - 10px) / 2);
                &:nth-child(1) {
                  margin-right: 10px;
                }
                &:nth-child(2) {
                  text-align: right;
                  color: #000000;   
                  font-weight: 500;
                }
              }
            }
          }
        }
    }
    .room-blocks {
        .room-block {
          background-color: #e2eaf1;
          &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
          padding: 15px;
          &:nth-child(even) {
            background-color: #fcfcfc;
          }
          h5 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
          }
          h6 {
            font-size: 13px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 0;
          }
          .level {
            text-align: right;
            font-size: 14px;
            font-weight: 500;
          }
        }
    }
    .main-info {
        ul.status {
            margin-bottom: 10px;
            display: inline-block;
            list-style: none;
            padding-left: 0;
          li {
            font-size: 16px;
            font-weight: 500; 
            display: inline-block;
            color: #000000;    
            &:not(:last-child) {
              border-right: 1px solid #bfbfbf;
              padding-right: 10px;
              margin-right: 10px;
            }
            .badge{
              font-size: 16px;
              font-weight: 500;   
              
            }
            span {
                &.ant-tag{
                    border-radius: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    display: inline-block;
                    padding: 0.4em 0.78em;
                    font-size: .625rem;
                    font-weight: 400;
                    line-height: 1;
                    color: #fff;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 20px;
                }    
              &.for-sale {
                font-weight: 500;
                color: #fff;
                background-color: #7dab0f;
                padding: 4px 14px;
                border-radius: 14px;
                vertical-align: middle;
                margin: 2px 5px 2px 0;
                display: inline-block;
              }
              &.under-construction {
                font-weight: 500;
                color: #fff;
                background-color: #e80d0d;
                padding: 4px 14px;
                border-radius: 14px;
                vertical-align: middle;
                margin: 2px 5px 2px 0;
                display: inline-block;
              }
            }
          }
        }
        .address {     
          font-size: 18px;
          font-weight: 500;     
          display: block;   
          margin-bottom: 5px;
          color: #000000;
        }
  
        .location {
          font-size: 16px;
          color: #333333;
          display: block;  
          margin-bottom: 5px;
        }
        ul.specs {
          display: inline-block;
          margin-bottom: 10px;
          padding-left: 0;
          list-style: none;
          li {
            font-size: 16px;
            color: #2A2A2A;  
            display: inline-block;
            &:not(:last-child) {
              margin-right: 15px;
            }
            img,svg {
              margin-right: 5px;
              vertical-align: baseline;
              max-width: 22px;
            }  
          }
        }
  
        .main-right {
          .listed-for {
            font-size: 16px;        
            font-weight: 500;
            padding-right: 10px;   
            position: relative;
            display: block;
            .dropdown{
              .dropdown-menu{
                right: 10px !important;
      min-width: auto;
      left: auto !important;
      a{
        background-color: #fff;
        color: #000000;
      }
              }
            }
            &.new {
              &::after {
                position: absolute;
                right: 0;
                top: 45%;
                transform: translateY(-50%);
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $primary;
                content: '';
              }
            }
            span {
              font-size: 20px;     
              font-weight: 600; 
              margin: 0 0 0 5px;  
              &.new {
                color: $primary;
              }  
              &.old {
                color: #7e7e7e;
              }
            }
          }    
          .estimated-price {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            .arrow-down {
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid $danger;
              display: inline-block;
              margin: 0 5px;
              position: relative;
              bottom: 2px;
            }
            .arrow-up {
              transform: rotate(180deg);
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid $success;
              display: inline-block;
              margin: 0 5px;
              position: relative;
              bottom: 2px;
            }
            .info {
              margin: 0 5px;
            }
          }
          a.chat-with-us {
            display: block;
            width: 75px;  
            text-decoration: none;
            margin: 10px 0 10px auto;
            img {
              display: block;
              margin: 0 auto 5px auto;
            }
            span {
              display: block;
              font-size: 13px;
              font-weight: 500;
              color: $primary;  
            }    
          }
        }
      }
      .ant-collapse{
        background-color: #ffffff;
        border: none;
        border-radius: 0;
        .ant-collapse-item{
          border-bottom: 1px solid #ececec !important;
          &.ant-collapse-item-active{
            .ant-collapse-header{
              &::after {
                content: "\f067";
                transform: rotate(-90deg);
              }
            }
          }
          
.ant-collapse-header{   
  padding: 15px 35px 15px 15px;
  position: relative;
  .anticon{
    display: none;
  }
  &::after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    border-radius: 50%;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    content: "\f068";
    background-color: $primary;
    background-image: unset;
    // background-image: url('/images/accordion.svg');
    position: absolute;
    right: 15px;
    // transform: translateY(-50%);
    top: 30px;
    width: 20px;
    height: 20px;
    transform: rotate(0deg);
    transition: all 0.15s;
  }

  .header-info{
    padding-left: 20px;
  }
  ul.links{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li{
      &:not(:last-of-type){
        margin-right: 7px;
      }
      a{
        font-size: 10px;
        font-weight: 500;
        color: #0d73c1;
        border: 1px solid #0d73c1;
        display: block;
        border-radius: 50px;
        padding: 4px 10px 3px 10px;
        transition: all .15s;
        margin: 2.5px 0 0;
      }
    }
  }
  h4{
    color: $primary;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
  }
}
.ant-collapse-content{
  .ant-collapse-content-box{
    padding-left: 80px;
  }
  a.btn-pro-school{
    font-size: 11px;
    font-weight: 500;
    color: #0d73c1;
    border: 1px solid #0d73c1;
    display: inline-block;
    border-radius: 50px;
    padding: 6px 15px;
    transition: all 0.15s;
    background-color: #0d73c1;
    color: #fff;
    margin-bottom: 15px;
  }
  .sub--head {
    font-size: 18px;
    margin-bottom: 8px;
}
ul.blocks{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    .block{
      margin-bottom: 8px;
      h5{
        font-size: 13px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 8px;
      }
h6{
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
}


    }
  }
}
}
        }
      }
}
.pre-pro-dtls-page{
  color: #000000  !important;
  .property-image-block{
    margin-top: 30px;
    .swiper-wrapper{
      .swiper-slide{
        img{
          &.prop-swiper-image{
            object-fit: cover;
            height: 300px;
          }
        }
      }
    }
  }
  .btnstyle{
    border: 1px solid #0d73c1;
    color: #0d73c1;
    padding: 5px;
    text-align: center; 
    text-decoration: none;
    display: block;
    font-size: 14px;
    width: 117px;
    transition: all .2s;
    border-radius: 20px;
    margin: 10px auto 0;
    font-weight: 500;
    &:hover{
        color: #fff;
        background-color: #0d73c1;
    }
}
 .description {
    p{
      color: #454545;
    line-height: 27px;
    }
  }
  ul.key-facts{
    list-style: none;
    column-count: 2;
    padding: 0;
    margin: 0;
   .two-span {
      font-size: 13px;
      font-weight: 500;
    }
    li{
      margin-bottom: 10px;
      .two{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        span{
          font-size: 13px;
          font-weight: 400;
          color: #333;
          display: inline-block;
          width: calc((100% - 10px)/2);
          &:first-child{
            margin-right: 10px;
          }
          &:nth-child(2){
            text-align: right;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .main-info {
    ul.status {
      margin-bottom: 10px;
      display: inline-block;
      list-style: none;
      padding-left: 0;
    li {
      font-size: 16px;
      font-weight: 500; 
      display: inline-block;
      color: #000000;    
      &:not(:last-child) {
        border-right: 1px solid #bfbfbf;
        padding-right: 10px;
        margin-right: 10px;
      }
      .badge{
        font-size: 16px;
        font-weight: 500;   
        
      }
      span {
          &.ant-tag{
              border-radius: 20px;
              font-size: 14px;
              font-weight: 500;
              display: inline-block;
              padding: 0.4em 0.78em;
              font-size: .625rem;
              font-weight: 400;
              line-height: 1;
              color: #fff;
              text-align: center;
              white-space: nowrap;
              vertical-align: baseline;
              border-radius: 20px;
          }    
        &.for-sale {
          font-weight: 500;
          color: #fff;
          background-color: #7dab0f;
          padding: 4px 14px;
          border-radius: 14px;
          vertical-align: middle;
          margin: 2px 5px 2px 0;
          display: inline-block;
        }
        &.under-construction {
          font-weight: 500;
          color: #fff;
          background-color: #e80d0d;
          padding: 4px 14px;
          border-radius: 14px;
          vertical-align: middle;
          margin: 2px 5px 2px 0;
          display: inline-block;
        }
      }
    }
  }
    .address {     
      font-size: 18px;
      font-weight: 500;     
      display: block;   
      margin-bottom: 5px;
      color: #000000;
    }

    .location {
      font-size: 16px;
      color: #333333;
      display: block;  
      margin-bottom: 5px;
    }
    ul.specs {
      display: inline-block;
      margin-bottom: 10px;
      padding-left: 0;
      list-style: none;
      li {
        font-size: 16px;
        color: #2A2A2A;  
        display: inline-block;
        &:not(:last-child) {
          margin-right: 15px;
        }
        img,svg {
          margin-right: 5px;
          vertical-align: baseline;
          max-width: 22px;
        }  
      }
    }

    .main-right {
      .listed-for {
        font-size: 16px;        
        font-weight: 500;
        padding-right: 10px;   
        position: relative;
        display: block;
        .dropdown{
          .dropdown-menu{
            right: 10px !important;
  min-width: auto;
  left: auto !important;
  a{
    background-color: #fff;
    color: #000000;
  }
          }
        }
        &.new {
          &::after {
            position: absolute;
            right: 0;
            top: 45%;
            transform: translateY(-50%);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid $primary;
            content: '';
          }
        }
        span {
          font-size: 18px;     
          font-weight: 600; 
          margin: 0 0 0 5px;  
          &.new {
            color: $primary;
          }  
          &.old {
            color: #7e7e7e;
          }
        }
      }    
      .estimated-price {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        .arrow-down {
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid $danger;
          display: inline-block;
          margin: 0 5px;
          position: relative;
          bottom: 2px;
        }
        .arrow-up {
          transform: rotate(180deg);
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid $success;
          display: inline-block;
          margin: 0 5px;
          position: relative;
          bottom: 2px;
        }
        .info {
          margin: 0 5px;
        }
      }
    }
  }
  ul.condo-specs {
    margin-bottom: 0;
    margin-top: 15px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 10px;
    margin-bottom: 15px;
    width: 125px;
    &:not(:last-child){
margin-right: 0;
    }
    img,svg{
      display: block;
      margin: 0 auto 10px;
    }
    span{
      font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    display: block;
    }
    }
}
}                                        

.category-scroll::-webkit-scrollbar {
  height: 10px;

}
.category-scroll {
  background-color: #e4ebf1;
  margin: 0;
  padding: 15px 0;
  overflow: auto;

  li {
    list-style: none;
    margin-right: 10px;
    flex: 0 0 auto;
    &:first-child {
      margin-left: 10px;
    }
    .cat-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 2px 15px 4px 15px;
      border-radius: 15px;
      color: #000000;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 600;
      // .image {
      //   margin-right: 5px;
      //   position: relative;
      // }
    }
  }
}


.similar-pre-condo-card .ant-card-body
{
    padding:0px;
    .similar-pre-condo-card-details
    {
        width: 100%;
        height: 250px;
        position: relative;
        .similar-pre-condo-card-img
        {
            object-fit: cover;
            border-radius: 4px;
        }
        .similar-pre-condo-card-overlay
        {
            position: absolute;
            bottom: 0;
            background-color: #0000004a;
            height: 50px;
            width: 100%;
        }
        .similar-pre-condo-card-prop
        {
            position: absolute;
            bottom: 10px;
            left: 15px;
            color: #ffffff;
            font-size: 15px;
            .sqft-sec{
              border-left: 1px solid white;
              padding-left: 5px;
              svg{
               
                  height: 16px !important;
              }
            }
        }
        .similar-pre-condo-card-status
        {
            position: absolute;
            top: 0;
            background-color: #7dab0f;
            color: white;
            font-size: 12px;
            padding: 7px 17px;
            border-top-left-radius: 4px;
            border-bottom-right-radius: 25px;
        }
    }
    .similar-pre-condo-card-info
    {
        margin: 15px;
        line-height: 21px;
        .similar-pre-condo-card-info-location
        {
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}