.admin-layout {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    background: #fff !important;
    padding-left: 80px;

    .ant-layout-sider {
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
    }
    .crm-wrapper-area{
        height: 100vh;
        position: relative;
        padding-top: 64px;
        width: 100%;
        overflow-x: hidden;
        padding-bottom: 30px;
        .ant-layout-header {
            position: fixed;
            top: 0;
            right: 0;
            left: 80px;
            z-index: 66;
            background-color: rgb(250, 250, 250);
            box-shadow: 1px 1px 7px -4px #0007;
        }
        .crm-footer {
            bottom: 0;
            position: fixed;
            right: 0;
            left: 80px;
            z-index: 2;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            padding: 3px;
            z-index: 20;
            .link{
                a{
                    font-weight: 400;
                    font-size: 15px;
                    color: $primary;
                }
            }
        }
    }
    .crm-content-area{
        display: flex;
        flex-direction: column;
        height: calc(100% - 110px);
        overflow-y: auto;
        @include smoothScroll();
        position: relative;
        >*{
            height: 100%;
            flex: 1;
        }
        .overlay-wrapper{
            display: flex;
            position: absolute;
            // top: 0;
            right: 0;
            // bottom: 0;
            left: 0;
            // z-index: 999;
            // padding: 10px;
            padding: 0px;
            // padding-top: 64px;
            z-index: 20;
            // height: calc(100% - 94px );
            // bottom: 30px;
            // top: 64px;
            height: 100%;
            .overlay-section{
                background-color: #fff;
                border-radius: 2px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                .overlay-section-body{
                    flex: 1
                }
            }
        
        }
    }
    .ant-layout {
        background: transparent !important;
    }
}


.infinite-scroll-component__outerdiv{
    height: 100% !important;
    .infinite-scroll-component {
        height: 100% !important;
    }
}









$listLayout: "listcontentlayout";

.listcontentlayout {
    display: flex;
    flex-direction: column;
    &-header {
        justify-content: space-between;
        align-items: center;
        padding: 4px 15px;
        display: flex;
        border-bottom: 1px solid #0001;
        height: 60px;
    }
    &-title {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        .back-btn{
            cursor: pointer;
            margin-right: 0.5rem;
            &:hover{
                color: $primary;
            }
        }
    }
    &-actions {
    }
    &-body {
        height: calc(100% - 60px);
        padding: 15px;
        &.relator-body-height{
            height: calc(100% - 135px);
        }
        .infinity-table{
      
        }
        .infinity-table{
            height: 100%;
            display: flex;
            flex-direction: column;
            .infinity-table-header{
                height: 45px;
            }
            .infinity-table-body{
                // overflow: auto;
                height: calc(100% - 45px);
                flex: 1;
                .ant-table-container,
                .ant-table,
                .ant-spin-container,
                .ant-spin-nested-loading,
                .ant-table-wrapper{
                    height: 100%;
                }
                .ant-table-header{
                    height: 48px;
                }
                .ant-table-body{
                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        // display: none;
                      }
                    height: calc(100% - 48px);
                }
            }
            &.loading-table{
                .ant-table-body{
                    position: relative;              
                    >*{
                        &::after{
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // background-image:linear-gradient(#fff,transparent);
                            background-color: white;
                            box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 15%);
                            content: 'Loading...';
                            width: 100%;
                            min-height: 30px;
                            padding: 17px 0;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            z-index: 100;
                        }
                    }
                
                }
            }
        }
    }
}

.innnercontentlayout {
    display: flex;
    flex-direction: column;
    &-header {
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        display: flex;
        border-bottom: 1px solid #0001;
    }
    &-title {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
    }
    &-actions {
    }
    &-body {
        padding: 15px;
    }
}






.base-page {
    display: flex;
    flex-direction: column;
    // background-color: #fff;

    // box-shadow: 1px 1px 14px -8px rgba(0, 0, 0, 0.527);
    .base-page-header {
        padding: 15px 20px;
        //    border-bottom: 1px solid rgb(182, 182, 182);
        //    background-color: #eee;
        .pg-title {
            font-size: 19px;
            font-weight: 600;
            i {
                margin-right: 10px;
            }
        }
    }
    .base-page-body {
        border-radius: 5px;
        background-color: #fff;
        padding: 15px 20px;
        box-shadow: 1px 1px 14px -8px rgba(0, 0, 0, 0.301);
    }
}

// base-page-section

.base-page-section {
    display: flex;
    flex-direction: column;
    .base-page-section-header {
        padding: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .base-page-section-title {
            font-size: 20px;
            margin: 0;
            color: $primary;
            text-transform: capitalize;
            font-weight: 500;
            display: flex;
            align-items: center;
            i {
                color: rgb(95, 95, 95);
                margin-right: 4px;
            }
        }
    }
    .base-page-section-body {
        padding: 10px;
        padding-top: 0;
        flex: 1;
        position: relative;
        .inner-page-flex {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
            }
        }
        .infinity-table {
            height: 100%;
            padding: 0;
            background: transparent;
            display: flex;
            flex-direction: column;
            .infinity-table-header {
                padding: 10px 0px;
                align-items: center;
                display: flex;
                height: 50px;
            }
            .infinity-table-body {
                flex: 1;
            }
            .ant-table-thead {
                height: 48px !important;
            }
        }
    }
}
