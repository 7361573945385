.integration {
 .item-card {
  .page-name {
   font-size: 18.5px;
   .actived-status {
    color: white;
    background-color: #59c72a;
    padding: 4px 6px;
    border-radius: 5px;
    font-size: 13px;
   }
  }
  .fa-facebook {
   color: #3b5998;
   font-size: 28px;
  }
  .fa-times {
   font-size: 14px;
   padding: 3px 8px;
   border-color: gray;
   border-radius: 4px;
   color: gray;
   border: 1px solid gray;
   cursor: pointer;
   &:hover {
    color: $primary;
    border-color: $primary;
   }
  }
  .link {
   &:not(:hover) {
    color: $primary;
   }
  }
  .active-btn{
    background-color: $success;
    color: white;
    border: none !important;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    padding: 4px 15px;
    width: max-content;
  }
 }
 .fb-login-btn{
  button{
    background-color: #e80d0d;
    border: 1px solid #e80d0d;
    color: #fff;
    cursor: pointer;
    padding: 4px 15px;
  }
 }
}
