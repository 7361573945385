.image{

    width: 100%;
    &._1x1{
       aspect-ratio: 1/1;
    }
    &._16x9{
        aspect-ratio: 16/9;
    }
    &._4x3{
        aspect-ratio: 4/3;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.bsImg {
    width: 100%;
    height: 100%;
    background-color: #ddd;
    // position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .imgLoader {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
