.mts-confirm {
    .ant-modal-content {
        .ant-modal-body {
            .ant-modal-confirm-body-wrapper {
                text-align: center;
                .ant-modal-confirm-body {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .con-icon-block {
                        width: 60px;
                        height: 60px;
                        background-color: rgba($warning, $alpha: 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-bottom: 13px;
                        i {
                            color: #0008;
                            font-size: 26px;
                        }
                    }
                    .ant-modal-confirm-title {
                        font-size: 25px;
                        margin-bottom: 1px;
                    }
                    .ant-modal-confirm-content {
                        color: #777;
                    }
                }
                .ant-modal-confirm-btns {
                    float: none;
                }
            }
        }
    }
}

// mts-speed-dial

// .mts-speed-dial {
//     // position: fixed;
//     // top: 0;
//     // right: 0;
//     // left: 0;
//     // bottom: 0;
//     // width: 100%;
//     // height: 100%;
//     // z-index: 99999;
//     z-index: 3;
//     .mts-speed-dial-mask {
//         transition: all .5s ease;
//         position: absolute;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         right: 0;
//         background-color: #0008;
//         z-index: 10;
//         opacity: 0;
//         display:none;
//     }
//     .mts-speed-dial-container {
//         position: absolute;
//         right: 20px;
//         bottom: 20px;
//         z-index: 20;
//         display: flex;
//         flex-direction: column;
//         align-items: flex-end;
//         .dial-clicker {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             i{
//                 color: #fff;
//                 font-size: 20px;
//                 transition: all .5s ease;
//             }
//             border-radius: 50%;
//             width: 56px;
//             height: 56px;
//             z-index: 1050;
//             background-color: $primary;
//             transition: all .5s ease;
//             transform: rotate(0);
//             // &:hover{
//             //     background-color: darken($primary, $amount: 10);
//             //     i{
//             //         transform: rotate(45deg);
//             //     }
//             // }
//             box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px,
//                 rgb(0 0 0 / 12%) 0px 1px 18px 0px;
//         }
//         .speed-dial-menus{
//             display: flex;
//             flex-direction: column;
//             margin: 0;
//             padding: 9px;
//             list-style: none;
//             align-items: flex-end;
//             li{
              
//                 &:not(:last-of-type){
//                     margin-bottom: 10px;
//                 }
//                 display: flex;
//                 align-items: center;
//                 .li-span-title{
//                     line-height: 1.3;
//                     text-transform: capitalize;
//                     opacity: 0;
//                     display: inline-block;
//                     background-color: #fff;
//                     border-radius: 8px;
//                     padding: 4px 10px;
//                     margin-right: 6px;
//                     font-size: 14px;
//                     transition: all .5s ease;
//                     box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px,
//                 }
//                 a{
//                     opacity: 0;
//                     transition: all .3s ease;
//                     transform: scale(0);
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     border-radius: 50%;
//                     color: #555;font-size: 17px;
//                     width: 40px;
//                     height: 40px;
//                     background-color: #fff;
//                     box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px,
//                     rgb(0 0 0 / 12%) 0px 1px 18px 0px;
//                     i{
//                         transition: all .5s ease;
//                     }
//                     &:hover{
//                         i{
//                             transform: scale(1.1);
//                         }
//                     }
//                     &.a-color{
//                         color: #fff;
//                     }
//                 }
//             }
//         }
//     }
//     &.mts-speed-dial-opened{
//         .mts-speed-dial-mask {
//             opacity: 1;
//             display: block;
//         }
//         .speed-dial-menus{
//             li{
//                 .li-span-title{
//                     opacity: 1;
//                 }
               
//                 // transition: all .5s ease;
//             a{
//                 transform: scale(1);
//                 opacity: 1;
//             }
//             }
//         }
//         .dial-clicker {
//             background-color: darken($primary, $amount: 10);
//             i{
//                 transform: rotate(45deg);
//             }
//         }
//     }
// }

.mts-speed-dial {
    z-index: 111;
    .mts-speed-dial-mask {
        transition: all .5s ease;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #0008;
        z-index: 1100;
        opacity: 0;
        display:none;
    }
    .mts-speed-dial-container {
      
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 1200;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .dial-clicker {
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                color: #fff;
                font-size: 20px;
                transition: all .5s ease;
            }
            border-radius: 50%;
            width: 56px;
            height: 56px;
            z-index: 1050;
            background-color: $primary;
            transition: all .5s ease;
            transform: rotate(0);
            box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px,
                rgb(0 0 0 / 12%) 0px 1px 18px 0px;
        }
        .speed-dial-menus{
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 9px;
            list-style: none;
            align-items: flex-end;
            li{
              
                &:not(:last-of-type){
                    margin-bottom: 10px;
                }
                display: flex;
                align-items: center;
                .li-span-title{
                    line-height: 1.3;
                    text-transform: capitalize;
                    opacity: 0;
                    display: inline-block;
                    background-color: #fff;
                    border-radius: 8px;
                    padding: 4px 10px;
                    margin-right: 6px;
                    font-size: 14px;
                    transition: all .5s ease;
                    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px,
                }
                a{
                    opacity: 0;
                    transition: all .3s ease;
                    transform: scale(0);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    color: #555;font-size: 17px;
                    width: 40px;
                    height: 40px;
                    background-color: #fff;
                    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px,
                    rgb(0 0 0 / 12%) 0px 1px 18px 0px;
                    i{
                        transition: all .5s ease;
                    }
                    &:hover{
                        i{
                            transform: scale(1.1);
                        }
                    }
                    &.a-color{
                        color: #fff;
                    }
                }
            }
        }
    }
    &.mts-speed-dial-opened{
        .mts-speed-dial-mask {
            opacity: 1;
            display: block;
        }
        .speed-dial-menus{
            li{
                .li-span-title{
                    opacity: 1;
                }

            a{
                transform: scale(1);
                opacity: 1;
            }
            }
        }
        .dial-clicker {
            background-color: red;
            i{
                transform: rotate(45deg);
            }
        }
    }
}