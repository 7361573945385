.sw-bar-realtor-form-item{
    .ant-form-item-control-input{
        border: 1px solid #ccc !important;
    }
}

.relator-detail-tail{
    padding: 0.5rem ;
    padding-left: 0;
    .item{
        padding: 1rem;
        border-radius: 10px;
        font-size: 16px;
        margin-right: 0.5rem;
        color: white;
        font-weight: 400;
        @include simpleBg($indigo)
    }
}