.m-audio {
    width: 100%;
    height: 35px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    .audio-control {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 30px;
        height: 100%;
        color: $primary;
    }
    .time-blk {
        font-size: 13px;
        text-align: center;
        width: 25px;
    }
    .audio-range {
        display: flex;
        width: calc(100% - 55px);
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 0px 7px;

        .time-blk {
            font-size: 13px;
        }
        .ant-slider-rail {
            background-color: lighten($primary, 40);
        }
        .ant-slider-handle {
            background-color: $primary;
        }
    }
}
