.action-timeline-view{
    margin-left: 20px;
    .tim-dot{
        text-align: center;
        h5{
            color: #666;
        }
        i{
            font-size: 19px;
        }
    }
    .ant-timeline-item-content{
        margin: 0 0 0 45px;
    }
    .ap-steps-email-body{
        img{
           max-width: 100%;
        }
    }
}



.page-size-modal{
    height: -webkit-fill-available;
    max-width: 100vw;

  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    top: 0;
    overflow: auto;
  }
  .ant-modal-centered::before {
    content: unset;
  }
  .ant-modal-footer {
    display: none;
  }
}