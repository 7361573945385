// .navbar {
//     background-color: rgb(255, 255, 255);
//     align-items: center;
//     padding: 0px 10px;
//     display: flex;
//     justify-content: space-between;
//     .menus {
//         margin: 0;
//         padding: 0;
//         list-style: none;
//         display: flex;
//         align-items: center;
//         justify-content: end;
//         // line-height: 64px;
//         // height: 100%;
//         > li {
//             > a {
//                 display: block;
//                 padding: 0 15px;
//                 color: #6c757d;
//                 min-width: 32px;
//                     height: 30px;
//                 // line-height: 64px;
//                 text-align: center;
//                 font-weight: 400;
//                 font-size: 15px !important;
//                 &.icon{
//                     font-size: 17px;
//                 }
  
//                 &.dropdown {
//                     align-items: center;
//                     display: flex;
//                     h5 {
//                         color: #6c757d !important;
//                         font-weight: 400;
//                         font-size: 14px !important;
//                     }
//                     i {
//                         padding-left: 6px;
//                     }
//                 }
//             }
//         }
//     }
// }

.inboxcalander{
   
    svg{
        height: 18px;
        width: 18px;
        margin: 5px;
       
        path{
            fill: #555555;

        }
        
    }
}

.chatIcon{
    svg{
        height: 20px;
        width: 20px;
        path{
            fill:#555555;
        }
    }
}

.notification{
    svg{
        height: 20px;
        width: 20px;
        path{
            fill:#555555;
        }
    }
}


.brokerageIcon{
    display: flex;
    svg {
    width: 17px  !important;
    height: 18px  !important;
   }
}