.prolisting-page{
    height: 100%;
    flex: 1;
    // display: flex;
    flex-direction: column;
    .listing-filter-blk{
        background-color: rgb(235, 235, 235);
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        >div{
            width: 100%;
        }
        // display: flex;
        // justify-content: space-between;
        // column-gap: 10px;
        transition: all .5s ease;
        &.isSticky{
            background-color: #fff;
            padding: 15px;
            border-bottom: 1px solid #000;
            box-shadow: 0px 5px 10px -3px #0005;
        }
  
        .input.ant-input{
            border-radius: 20px;
        }
    }
    .pro-listing-block{
        height:calc(100% - 60px);
        overflow: auto;
        padding: 15px;
        @include smoothScroll();
        // .pro-scroll{
        //     overflow: auto;
        //     height: 100%;
        //     padding: 15px;
        //     @include smoothScroll();

        // }
 
    }
}

