

@mixin smoothScroll {
    &::-webkit-scrollbar {
        background:lightgray;
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(180, 180, 184); 
        border-radius: 15px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        -webkit-box-shadow: inset 0 0 6px rgba(77, 77, 77, 0); 
        background:$primary;
    } 
}

@mixin simpleBg($color) {
    background-image: linear-gradient(to right,$color,darken($color,6));
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 10px;
    &::after{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        content: '';
        background-image: linear-gradient(to right,#fff4,transparent);
        z-index: -1;
        border-radius: 10px;
    }
}
