@import 'react-phone-input-2/lib/plain.css';
@import "../../node_modules/antd/dist/antd.variable.min.css";
@import "./plugin/bootstrap-utilities.min.css";

@import './mixins';
@import "./var";
@import "./utils";

@import './antd-custom.scss';

@import "./components/button";
@import "../components/elements/Mcomponents/m-components.scss";
@import "../components/elements/elements.scss";
@import '../components/elements/base-components.scss';
@import '../components/elements/SWToBar/sw-bar.scss';


// @import "./less/index.less";

//Core files





@import '../features/auth/style/index.scss';
@import '../features/leads/lead.scss';
@import '../features/inbox/inbox.scss';
@import '../features/property/property.scss';
@import '../features/integration/integration.scss';
@import '../layout/layout.scss';
@import '../components/layout-modules/layout-module.scss';
@import '../features/misc/style.scss';
@import '../features/action-plan/actionplan.scss';
@import '../features/calendar/calendar.scss';
@import '../features/realtors/realtor.scss';
@import '../features/settings/settings.scss';
@import '../components/shared/MDataTable/style.scss';
@import '../components/shared/SendWidget/style.scss';





// @import './boots.scss';

// Plugins
// @import "./antd/index";
@import "./pages/auth-page";
@import "./pages/lead-styles";
@import "./pages/lead2style";
@import "./pages/actionplan";
@import "./pages/pro-listing-page";
@import "./pages/pro-detailspage";
@import "./pages/layout";




// @import "./components/buttons";

// @import './modules/layouts';
@import './modules/common';
@import './uicomponents';




// Import Swiper styles
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'swiper/css/scrollbar';


body{
    &::-webkit-scrollbar {
        background:lightgray;
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(180, 180, 184); 
        border-radius: 15px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        -webkit-box-shadow: inset 0 0 6px rgba(77, 77, 77, 0); 
        background:$primary;
    } 
    * {
        @include smoothScroll()
    }
}



// @import "./components/accordions";
// @import "./components/badge";
// @import "./components/backgrounds";
// @import "./components/breadcrumb";

// @import "./components/card";
// @import "./components/dropdown";
// @import "./components/forms";
// @import "./components/modal";
// @import "./components/nav";
// @import "./components/pagination";
// @import "./components/popover";
// @import "./components/progress";
// @import "./components/reboot";
// @import "./components/tables";
// @import "./components/type";
