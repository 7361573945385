.authLayout {
    width: 100%;
    height: 100vh;
    background-color:$primary;
    display: flex;
    align-items: center;
    justify-content: center;
    .authContent {
        background-color: #fff;
        padding: 30px 15px;
        .logoblock {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}

.grecaptcha-badge
{
    display: none !important;
}