.dashboard-page {
 background-color: rgb(241, 241, 241);
 height: 100%;
 //  overflow-y: auto;
 overflow: hidden;
 .ant-row {
  .ant-col {
   &:nth-child(1) {
    .dash-card {
     @include simpleBg($primary);
    }
   }
   &:nth-child(2) {
    .dash-card {
     @include simpleBg($purple);
    }
   }
   &:nth-child(3) {
    .dash-card {
     @include simpleBg($cyan);
    }
   }
   &:nth-child(4) {
    .dash-card {
     @include simpleBg($orange);
    }
   }
   &:nth-child(5) {
    .dash-card {
     @include simpleBg($success);
    }
   }
   &:nth-child(6) {
    .dash-card {
     @include simpleBg($indigo);
    }
   }
   &:nth-child(7) {
    .dash-card {
     @include simpleBg($indigo);
    }
   }
  }
 }
 .ant-card {
  box-shadow: 1px 1px 10px -2px #0004;
  border: none;
 }
 .dash-card {
  position: relative;
  color: #fff;

  // &.dash-card-primary{
  //     background-color: darken($primary, 18);
  // }
  // &.dash-card-secondary{
  //     background-color: darken($primary, 9);
  // }
  .dash-title {
   font-weight: 600;
   font-size: 22px;
   color: #fff;
  }
  .extra-dash {
   display: flex;
   margin: 0;
   padding: 0;
   list-style: none;
   li {
    padding: 0px 7px;
    // box-shadow: 1px 1px 3px -1px #0004;
    // border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h6 {
     font-size: 15px;
     color: #fff;
     margin-bottom: 0;
     line-height: 1;
     text-transform: capitalize;
     margin-bottom: 8px;
    }
    p {
     font-size: 15px;
     font-size: 800;
     color: #fff;
     margin: 0;
     line-height: 1;
    }
    &:not(:last-of-type) {
     border-right: 2px solid #0002;
     // margin-right: 10px;
    }
   }
  }
  .dash-icon {
   position: absolute;
   right: 10px;
   top: 10px;
   overflow: hidden;
   height: 70px;
   width: 70px;
   background-color: #fff2;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 10px;
   box-shadow: 1px 1px 10px -2px #0006;
   i {
    font-size: 20px;
   }
  }
 }
 .table-card {
  overflow: auto;
  height: 100%;
  .table-ttl {
   font-size: 16px;
   font-weight: 600;
   margin-bottom: 12px;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small .ant-table-thead > tr > th {
   padding: 6px 10px;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
   font-size: 13px;
   //    width: 200px !important;
  }
  .last-activity {
   display: -webkit-box;
   -webkit-line-clamp: 5;
   -webkit-box-orient: vertical;
   overflow: hidden;
  }
  .ant-tooltip {
   .ant-tooltip-inner {
    .tssblock-span {
     white-space: break-spaces !important;
    }
   }
  }
 }
 .recent-active-heading {
  font-size: 16px;
  font-weight: 600;
  //   margin-bottom: 12px;
  background-color: white;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
 }

 .ant-card-body {
  overflow: auto;
  height: calc(100% - 133px);
  &::-webkit-scrollbar {
   width: 8px;
   height: 8px;
  }
 }
}

.dashboardcard-icons {
 svg {
  height: 19px;
  width: 19px;
  path {
   fill: white;
  }
 }
}

.dashboard-table-items {
 display: -webkit-box;
 -webkit-line-clamp: 1;
 -webkit-box-orient: vertical;
 overflow: hidden;
 max-width: 180px;
 width: 180px;
// min-width: 180px;
// width: 100%;
}
