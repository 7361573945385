
.mts-collapse{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border: unset !important;
    .ant-collapse-item{
        &.ant-collapse-no-arrow{
            background-color: rgb(247, 247, 247);
            border: 1px solid #ccc;
            border-radius: 7px;

        }
        .ant-collapse-header{
            position: relative;
            &::after{
                transition: all .5s ease;
                position: absolute;
                top: 15px;
                right: 15px;
                content: "\f358";
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                font-size: 20px;
            }
        }
        &.ant-collapse-item-active{
            .ant-collapse-header{
                &::after{
                    transform: rotate(180deg);
                }
            }
        }
        .ant-avatar{
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ccc !important;
            svg{
                width: 100%;
                height: auto;
            }
        }
        // &:not(:last-of-type){
        //     margin-bottom: 6px;
        // }
    }
    .mail-preview-activity{
        .bold{
        font-weight: 600;
        font-size: 16px;
    }
    .body{
        font-size: 16px;
    }
    .message{
        a{
            color: $primary;
        }
    }
    }
}

.fullScreenModal  {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    max-width: 100%;
    padding: 15px;
    height: 100%;
    .ant-modal-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 10px;
        .ant-modal-body {
            flex: 1;
            overflow-y: auto;
           }
    }
    // &.ant-modal, .ant-modal-content {
    //     height: 100vh;
    //     width: 100vw;
    //     margin: 0;
    //     top: 0;
    //    }
       
}

.form-label{
    font-size: 15px;
    color: #888;
    margin-bottom: 4px;
    display: block;
}

.btnanch-disable{
    cursor: not-allowed;
    position: relative;
    &::after{
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 11;
    }
}

.addon-button-input{
    position: relative;
    .mts-input-grp{
        display: flex;
        .input-bllk{
            flex: 1;
        }
        .btn-action{
            width: 40px;
            .ant-btn{
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border: 1px solid #ced4da !important;
            }
        }
    }
    // .ant-input{
    //     height: 100%;
    // }
    // .ant-input-group-addon{
    //     padding: 0;
    // }
}

.btn-checkbox{
    // border: 1px solid $primary;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin: 0;
    padding: 4px 10px;
    width: 100%;
    display: flex;
    min-width: 150px;
    &::after{
        content: none;
    }
    &.ant-radio-wrapper-checked,
    &.ant-checkbox-wrapper-checked{
        // background-color: $primary;
        background-color: white;
        // color: #fff;
        color: black;
        border: 1px solid $primary;
    }
    .ant-checkbox{
        .ant-checkbox-inner{
            display: none;
        }
        &+span{
            display: block;
            text-overflow:ellipsis;
            white-space: nowrap;
            padding: 0;
        }
        &.ant-checkbox-checked{
            &::after{
                content: none;
            }
        }
    }
    .ant-radio{
        .ant-radio-inner{
            display: none;
        }
        &+span{
            display: block;
            text-overflow:ellipsis;
            white-space: nowrap;
            padding: 0;
        }
        &.ant-checkbox-checked{
            &::after{
                content: none;
            }
        }
    }
}

// .react-resizable {
//     position: relative;
//     background-clip: padding-box;
//   }
  
// .react-resizable-handle {
//     position: absolute;
//     right: -5px;
//     bottom: 0;
//     // z-index: 1;
//     width: 10px;
//     height: 100%;
//     cursor: col-resize;
//   }

// base-loader

.base-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    &.base-loader-full{
        width: 100%;
        height: 100%;
    }
}
.radio-filter{
    .ant-radio-button-wrapper{
        margin-left: 8px;
        &:not(:last-child){
            margin-right: 15px;
        }
    }
}



// DATA TABLE


// .infinity-table {
//     background-color: #fff;
//     border-radius: 10px;
//     // padding: 15px;
//     .infinity-table-body{
//         display: flex;
//         flex-direction: column;
//         .ant-table-body{
           
//             &::-webkit-scrollbar {
//                 display: none;
//               }
//         }
//         .ant-table-wrapper.mts-table{
//             flex: 1;
//             max-height: 100%;
          
//         }
//     }
    
// }

.ant-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-descriptions{
    .ant-descriptions-row{
        .ant-descriptions-item{
            .ant-descriptions-item-container{
                .ant-descriptions-item-label{
                    color: #888;
                }
                .ant-descriptions-item-content{
                    color: #222;
                    font-weight: 400;
                }
            }
        }
    }
}

.custom-scroll {
    @include smoothScroll()
}



.inputAddress{
&.ant-select-multiple.ant-select-disabled{
    .ant-select-selector{
        background-color: transparent;
    }
    .ant-select-selection-item {
        color: #222;
        border-color: #999;
        cursor: not-allowed;
        background-color: #ddd;
    }
}

}

.tssblock-tag{
    
    .tssblock-span{
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
           padding-bottom: 2px;
        }

    // line-height: 1;
    // padding: 3px;
    // .tssblock-span{
    //     display: inline-block;
    //     line-height: 1;
    //     max-width: 100px;
    //     overflow: hidden;
    //     padding-bottom: 2px;
    //     text-overflow: ellipsis;
    // }
}