
.proTag {
        border-radius: 10px;
        padding: 0 5px;
        font-size: 10px;
        line-height: 14px;
}
.bbc{
        width: max-content;
        svg{
                width: 15px;
                height: 15px;
                object-fit: contain;
                line-height: 1;
                margin-right: 3px;
                *{
                        fill: #555;
                }
        }
        .bbcCol{
                display: flex;
                align-items: center;
                line-height: 1;
                // border: 1px solid blue;
                margin-right: 8px;

        }
        .bbcSpan{
                font-size: 13px;
        }
}