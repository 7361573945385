.base-navbar {
 padding: 0 20px;
 line-height: unset;
 display: flex;
 align-items: center;
 
 .nav-links-header {
  display: flex;
  list-style: none;
  margin: 0px -7px;
  padding: 0;

  li {
   padding: 0px 7px;
   display: flex;
   align-items: center;
   a {
    display: flex;
    align-items: center;

    i {
     font-size: 16px;
     margin-right: 7px;
    }

    &.active {
     padding: 5px 15px;
     background-color: $primary;
     color: #fff;

     border-radius: 50px;
     box-shadow: 1px 1px 5px 2px #0004;
    }
   }
  }
 }

}
