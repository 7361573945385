
.innerpage-sec{
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.849);
    border-radius: 10px;
    // padding: 30px;

    .innerpage-header{
        padding: 30px;
        position: relative;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .inner-close{
            position: absolute;
            right: 20px;
            top: 20px;
        }
        .inner-title{
            font-size: 20px;
            font-weight: 600;
            margin: 0;
        }
    }
    .innerpage-body{
        padding: 30px;
    }
}

