$white: #ffffff !default;
$black: #000000 !default;
$red: #de4436 !default;
$orange: #fa8c16 !default;
$gold: #ffc542 !default;
$green: #21b573 !default;
$blue: #3e82f7 !default;
$cyan: #04d182 !default;
$purple: #a461d8 !default;
$magenta: #eb2f96 !default;
$volcano: #ff6b72 !default;
$lime: #a0d911 !default;
$geekblue: #17bcff !default;
$blue: #007bff;
$indigo: #675aa9;
$purple: #6658dd;
$pink: #f672a7;
$red: #f86262;
$orange: #fd7e14;
$yellow: #fcc015;
$green: #0d7e38;
$teal: #02a8b5;


$primary: #ED1C24;
$secondary: #490250;
$success: #7dab0f;
$info: #084879;
$warning: $yellow;
$danger: #e80d0d;
$alpha:#FF8000;
$beta:#FF0000;
$gamma:#41BC00;

$saleColor: $success;
$soldColor: $danger;  
$rentColor: #0298d1;
$leasedColor: #16be90;

$border-radius : 6px ;



:root {
    --bs-primary: #{$primary};
    --bs-secondary: #{$secondary};
    --bs-success: #{$success};
    --bs-info:#{$info};
    --bs-warning: #{$warning};
    --bs-danger: #{$danger};
    --bs-rent: #{$rentColor};
    --bs-sale: #{$saleColor};
    --bs-sold: #{$soldColor};
    --bs-leased: #{$leasedColor};
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 237, 28, 36;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
  }
  
 

